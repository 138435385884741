import React from "react"

import ProductCoverageAreaStates from "./ProductCoverageAreaStates"
import ProductCoverageAreaMsas from "./ProductCoverageAreaMsas"
import ProductNameTerms from "./ProductNameTerms"
import ProjectBi from "./ProjectBi"
import MrfType from "./MrfType"
import MrfDate from "./MrfDate"
import MrfOrderName from "./MrfOrderName"
import RefCarrierDirectory from "./RefCarrierDirectory"
import RefDataProject from "./RefDataProject"
import RefOrganization from "./RefOrganization"
import DataOrderFromExistingProject from "./DataOrderFromExistingProject"
import CarrierForOrder from "./CarrierForOrder"
import IndexFileForOrder from "./IndexFileForOrder"
import MrfOrderAlias from "./MrfOrderAlias"

import UserState from "./UserState"
import UserFacilityName from "./UserFacilityName"

export { ProductCoverageAreaStates }

export default function TypeCustom(props) {
  const { fieldname } = props
  switch (fieldname) {
    case "product_coverage_area_states":
      return <ProductCoverageAreaStates {...props} />
    case "product_coverage_area_msas":
      return <ProductCoverageAreaMsas {...props} />
    case "project_bi":
      return <ProjectBi {...props} />
    case "product_name_terms":
      return <ProductNameTerms {...props} />
    case "mrf_type":
      return <MrfType {...props} />
    case "mrf_order_alias":
      return <MrfOrderAlias {...props} />
    case "mrf_order_name":
      return <MrfOrderName {...props} />
    case "mrf_date":
      return <MrfDate {...props} />
    case "ref_carrier_directory":
      return <RefCarrierDirectory {...props} />
    case "ref_data_project":
      return <RefDataProject {...props} />
    case "ref_organization":
      return <RefOrganization {...props} />
    case "data_order_from_existing_project":
      return <DataOrderFromExistingProject {...props} />
    case "carrier_for_order":
      return <CarrierForOrder {...props} />
    case "index_file_for_order":
      return <IndexFileForOrder {...props} />
    case "user_state":
      return <UserState {...props} />
    case "user_facility_name":
      return <UserFacilityName {...props} />
    default:
      return null
  }
}

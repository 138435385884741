import React, { useState, useEffect } from "react"
import axios from "axios"
import { Field } from "formik"
import { Dropdown } from "primereact/dropdown"

import { dataRoomURL } from "../../../config/cloudFunctionsURL"

// constants
import { GET } from "../../../config/constants"

import styles from "../../../styles/Form.module.css"
import { LoaderSkeleton } from "../../Loaders"

export default function UserState(props) {
  const [fetching, setFetching] = useState(true)

  const { fieldname, label } = props

  const [stateList, setStateList] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const url = `${dataRoomURL()}/${GET.USER_STATE}`

      const result = await axios.post(url, {})

      setStateList(result.data.options)
      setFetching(false)
    }
    fetchData()
  }, [])

  if (fetching) return <LoaderSkeleton count={1} />

  return (
    <Field name={fieldname}>
      {({ field, meta }) => {
        return (
          <div className={styles.field}>
            <label htmlFor={fieldname} className={styles.label}>
              {label}
            </label>
            <Dropdown
              id={fieldname}
              options={stateList}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
            />
            {meta.touched && meta.error && (
              <div className={styles.error}>{meta.error}</div>
            )}
          </div>
        )
      }}
    </Field>
  )
}

import React from "react"

// CONST
import ROUTES from "./routesConstants"

// Pages

import Home from "../../pages/Home"
import Denied from "../../pages/Denied"
import IndexSearch from "../../pages/IndexSearch"
import IndexSearchSummary from "../../pages/IndexSearch/Summary"

import * as CarrierDirectory from "../../pages/CarrierDirectory"
import * as IndexFile from "../../pages/IndexFile"
import * as DataOrder from "../../pages/DataOrders"
import * as Projects from "../../pages/Projects"
import * as MRF from "../../pages/MRF"
import * as User from "../../pages/Users"
import * as Organization from "../../pages/Organizations"
import * as HospitalDirectory from "../../pages/HospitalDirectory"

// DataRoom
import DataRoom from "../../pages/DataRoom"
import * as AnalyticDashboard from "../../pages/DataRoom/AnalyticDashboard"
import * as ProviderNetworkMap from "../../pages/DataRoom/ProviderNetworkMap"
import * as NetworkSelection from "../../pages/DataRoom/NetworkSelection"
import * as PriceRateModeling from "../../pages/DataRoom/PriceRateModeling"

const routesDef = [
  {
    path: ROUTES.DENIED,
    requireAdmin: false,
    element: <Denied />,
  },
  {
    path: ROUTES.HOMEPAGE,
    requireAdmin: true,
    leastPrivilege: 15,
    element: <Home />,
  },
  {
    path: ROUTES.CARRIER_DIRECTORY_ADD,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <CarrierDirectory.Add />,
  },
  {
    path: ROUTES.CARRIER_DIRECTORY_LIST,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <CarrierDirectory.List />,
  },
  {
    path: `${ROUTES.CARRIER_DIRECTORY_EDIT}/:docId`,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <CarrierDirectory.Edit />,
  },
  {
    path: ROUTES.INDEX_FILE_ADD,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <IndexFile.Add />,
  },
  {
    path: ROUTES.INDEX_FILE_LIST,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <IndexFile.List />,
  },
  {
    path: `${ROUTES.INDEX_FILE_EDIT}/:docId`,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <IndexFile.Edit />,
  },
  {
    path: ROUTES.DATA_ORDER_ADD,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <DataOrder.Add />,
  },
  {
    path: ROUTES.DATA_ORDER_LIST,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <DataOrder.List />,
  },
  {
    path: `${ROUTES.DATA_ORDER_EDIT}/:docId`,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <DataOrder.Edit />,
  },
  {
    path: `${ROUTES.DATA_ORDER_DETAILS}/:docId/:status`,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <DataOrder.Details />,
  },
  {
    path: `${ROUTES.DATA_ORDER_DETAILS_PRE_PROCESS}/:docId/:status`,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <DataOrder.DetailsPreProcess />,
  },
  {
    path: ROUTES.PROJECT_ADD,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <Projects.Add />,
  },
  {
    path: `${ROUTES.PROJECT_EDIT}/:docId`,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <Projects.Edit />,
  },
  {
    path: `${ROUTES.PROJECT_DETAILS}/:docId`,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <Projects.Details />,
  },
  {
    path: ROUTES.MRF_LIST,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <MRF.List />,
  },
  {
    path: `${ROUTES.SEARCH_INDEX}/:type/:mrfOrder/:screen`,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <IndexSearch />,
  },
  {
    path: `${ROUTES.SEARCH_INDEX_SUMMARY}/:dataOrder`,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <IndexSearchSummary />,
  },
  {
    path: ROUTES.USER_ADD,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <User.Add />,
  },
  {
    path: ROUTES.USER_ADD_HFMA,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <User.AddHFMA />,
  },
  {
    path: ROUTES.USER_ADD_HFMA,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <User.Add />,
  },
  {
    path: ROUTES.USER_LIST,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <User.List />,
  },
  {
    path: `${ROUTES.USER_EDIT}/:docId`,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <User.Edit />,
  },
  {
    path: ROUTES.ORG_ADD,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <Organization.Add />,
  },
  {
    path: ROUTES.ORG_LIST,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <Organization.List />,
  },
  {
    path: `${ROUTES.ORG_EDIT}/:docId`,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <Organization.Edit />,
  },
  {
    path: ROUTES.HOSPITAL_DIRECTORY_ADD,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <HospitalDirectory.Add />,
  },
  {
    path: ROUTES.HOSPITAL_DIRECTORY_LIST,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <HospitalDirectory.List />,
  },
  {
    path: `${ROUTES.HOSPITAL_DIRECTORY_EDIT}/:docId`,
    requireAdmin: true,
    leastPrivilege: 9,
    element: <HospitalDirectory.Edit />,
  },
  // ---------------- DATA ROOM  ---------------- //
  {
    path: ROUTES.DATA_ROOM,
    requireAdmin: true,
    leastPrivilege: 15,
    element: <DataRoom />,
  },
  {
    path: ROUTES.COMMERCIAL_INSURANCE_NEGOTIATED_RATES,
    requireAdmin: true,
    leastPrivilege: 15,
    element: <AnalyticDashboard.CommercialInsuranceNegotiatedRates />,
  },
  {
    path: ROUTES.COMPARATIVE_COST_MODELS,
    requireAdmin: true,
    leastPrivilege: 15,
    element: <AnalyticDashboard.ComparativeCostModels />,
  },
  {
    path: ROUTES.BILLING_CODE_ANALYTICS,
    requireAdmin: true,
    leastPrivilege: 15,
    element: <AnalyticDashboard.BillingCodeAnalytics />,
  },
  {
    path: ROUTES.CARRIER_NETWORK_ANALYTICS,
    requireAdmin: true,
    leastPrivilege: 15,
    element: <AnalyticDashboard.CarrierNetworkAnalytics />,
  },
  {
    path: ROUTES.PROVIDER_NETWORK_REGIONAL_MAP,
    requireAdmin: true,
    leastPrivilege: 15,
    element: <ProviderNetworkMap.ProviderNetworkRegionalMap />,
  },
  {
    path: ROUTES.PROVIDER_NETWORK_CODE_MAP,
    requireAdmin: true,
    leastPrivilege: 15,
    element: <ProviderNetworkMap.ProviderNetworkCodeMap />,
  },
  {
    path: ROUTES.COMMERCIAL_INSURANCE_CARRIER_NETWORK_COMPARISONS,
    requireAdmin: true,
    leastPrivilege: 15,
    element: <NetworkSelection.CommercialInsuranceCarrierNetworkComparisons />,
  },
  {
    path: ROUTES.PRICE_RATE_MODELING_CARRIERS_AND_FACILITY_SYSTEMS,
    requireAdmin: true,
    leastPrivilege: 15,
    element: <PriceRateModeling.CarriersAndFacilitySystems />,
  },
]

export { routesDef }

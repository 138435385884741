const ENTITY = {
  CARRIER: "carrier_directory",
  MRF_ORDER: "mrf_orders",
  MRF: "mrfs",
  DATA_ORDER: "data_orders",
  PROJECT: "projects",
  USER: "users",
  USER_HFMA: "users_hfma",
  ORGANIZATION: "organizations",
  HOSPITAL: "hospital_directory",
}

const DEF = {
  FORM: "getFormDefinition",
}

const DELETE = {
  DATA_ORDER: "deleteCascadeDataOrder",
  MRF_ORDER: "deleteCascadeMrfOrder",
  USER_PROFILE: "deleteUserProfile",
  ORGANIZATION: "deleteOrganization",
  HOSPITAL: "deleteHospital",
}

const ADD = {
  CARRIER: "createCarrier",
  MRF_ORDER: "createMrfOrder",
  DATA_ORDER: "createDataOrder",
  PROJECT: "createProject",
  USER: "createUserProfile",
  USER_HFMA: "createUserProfile",
  ORGANIZATION: "createOrganization",
  HOSPITAL: "createHospital",
}

const EDIT = {
  CARRIER: "updateCarrier",
  DATA_ORDER: "updateDataOrder",
  MRF_ORDER: "updateIndexMrfOrder",
  PROJECT: "updateProject",
  USER: "updateUserProfile",
  USER_HFMA: "updateUserProfile",
  ORGANIZATION: "updateOrganization",
  HOSPITAL: "updateHospital",
}

const SET = {
  ADD_MRFS_TO_ORDER: "addMrfsToOrder",
  ADD_MRF_ORDER_EXPRESS: "addMrfToOrderExpress",
  PLACE_ORDER: "placeOrder",
}

const GET = {
  USER_PROFILE: "getUserProfile",
  CARRIER_LIST: "getCarrierDirectoryList",
  DATA_ORDER_SUMMARY: "getDataOrderSummary",
  PROJECT_LIST: "getProjectList",
  MRF_ORDER_INDEX_LIST: "getMrfOrderIndexList",
  PRODUCT_COVERAGE_AREA: "getProductCoverageArea",
  SEARCH_CARRIER_PLAN_DIST_BY_MARKET_TYPE:
    "getCarrierPlanDistributionByMarketType",
  SEARCH_PLANS_BY_ATTRIBUTE: "searchPlansByAttribute",
  SEARCH_CARRIER_PLAN_DIST_BY_ATT: "getCarrierPlanDistributionByAttribute",
  CARRIER_DIST_BY_MARKET_TYPE: "getCarrierPlanDistributionByMarketType",
  CARRIER_DIST_BY_ATT: "getCarrierPlanDistributionByAttribute",
  GET_LOCATIONS_BY_TIC: "getInNetworkLocationsByTicUuid",
  GET_PLAN_NAMES_BY_LOCATION: "getPlanNamesByLocationsAndPlans",
  GET_PROCESS_SUMMARY: "getProcessesSummary",
  GET_PROJECT_SUMMARY: "getProjectSummary",
  GET_PRE_PROCESS_SUMMARY: "getPreProcessSummary",
  GET_DATA_ORDER_NAME: "getDataOrderName",
  GET_PROJECT_METADATA_FROM_DATA_ORDER: "getProjectMetadataFromDataOrder",
  GET_MRF_ORDER_BY_REF: "getMrfOrderByReference",
  ORGANIZATION_LIST: "getOrganizationList",
  PROJECT_METADATA_FROM_MRF_ORDER: "getProjectMetadataFromMrfOrder",
  // HFMA: USERS
  USER_STATE: "api/selectors/user-management/state",
  USER_FACILITY_NAME: "api/selectors/user-management/facility-name",
}

export { ENTITY, DEF, DELETE, ADD, EDIT, SET, GET }
